<template>
  <div class="video-roi">
    <canvas id="canvas" style="z-index: 10; background-color: transparent;"></canvas>
    <div v-if="!mseUrl" ref="noVideo" class="no-stream-info">No Streaming Available</div>
    <video v-show="mseUrl" ref="video" class="video" loop muted 
      @click.prevent @loadedmetadata="handleLoadedMetadata">
      <source :src="mseUrl"/>
    </video>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import mse from '@/api/mse.js'
import { fabric } from 'fabric'

export default {
  name: 'VideoRoi',
  data() {
    return {
      player: null,
      playPromise: null,
      canvas: null,
      roi: null,
      faceRoi: null,
      faceRoiText: null,
      videoW: 0,
      videoH: 0,
      faceRoiW: 0,
      videoRealWidth: 0,
      minFaceRatio: 5,
      minFaceRoiW: 60,
      maxFaceRoiW: 500,
    }
  },
  computed: {
    ...mapState(['liveList']),
    ...mapState('aiboxFr', ['mode', 'isEditRoi', 'currDefaultSetting']),
    ...mapGetters('aiboxFr', ['userIndex', 'faceSizeRatio', 'currentSetting']),
    mseUrl() {
      let live = this.liveList.find(live => live.index == this.userIndex)
      if (live) {
        return live.mseUrl
      } else return null 
    },
    faceRatio: {
      get() {
        return this.faceSizeRatio
      },
      set(val) {
        this.updateFaceSizeRatio(val)
      }
    },
    ROI: {
      get() {
        return this.currentSetting.setting.channel[0].fr.roi
      },
      set(val) {
        this.updateROI(val)
      }
    },
    canEditRoi() {
      if (this.mode === 'view' || this.currDefaultSetting === 1) return false
      return this.isEditRoi
    },
    canEditTargetRoi() {
      if (this.mode === 'view' || this.currDefaultSetting === 1) return false
      return !this.isEditRoi
    },
  },
  watch: {
    mode() {
      this.handleSelectable()
    },
    currDefaultSetting() {
      this.handleSelectable()
    },
    isEditRoi() {
      this.handleSelectable()
    },
    faceRatio() {
      if (this.faceRatio < this.minFaceRatio) {
        this.updateFaceSizeRatio(this.minFaceRatio)
      }
      this.updateFaceRoiByRatio()
    },
    ROI: {
      handler() {
        this.roi.set({
          left: this.videoW * this.ROI.x1,
          top: this.videoH * this.ROI.y1,
          width: this.videoW * (this.ROI.x2 - this.ROI.x1) - 4,
          height: this.videoH * (this.ROI.y2 - this.ROI.y1) - 4,
          scaleX: 1,
          scaleY: 1,
        })
        this.canvas.renderAll()
      },
      deep: true,
    },
  },
  mounted() {
    this.onInit()
    window.addEventListener('resize', this.windowResize)
  },
  beforeDestroy() {
    this.pauseVideo()
    window.removeEventListener('resize', this.windowResize)
  },
  methods: {
    ...mapMutations('aiboxFr', ['updateFaceSizeRatio', 'updateROI']),
    onInit() {
      this.playVideo()
      if (!this.canvas)
        this.canvas = new fabric.Canvas('canvas')
      const [videoW, videoH] = this.getVideoWidthHeight()
      this.canvas.setWidth(videoW)
      this.canvas.setHeight(videoH)
      this.videoW = videoW
      this.videoH = videoH

      this.roi = new fabric.Rect({
        name: 'roi',
        left: this.videoW * this.ROI.x1,
        top: this.videoH * this.ROI.y1,
        fill: 'transparent',
        stroke: '#ffffff',
        strokeWidth: 2,
        width: this.videoW * (this.ROI.x2 - this.ROI.x1) - 4,
        height: this.videoH * (this.ROI.y2 - this.ROI.y1) - 4,
        cornerSize: 10,
        cornerColor: '#ffffff',
        transparentCorners: false,
        lockRotation: true, // 禁止旋轉
        selectable: this.mode === 'edit' && this.isEditRoi ? true : false,
        evented: this.mode === 'edit' && this.isEditRoi,
      })

      this.faceRoiW = this.videoW * this.faceRatio / 100
      const initLeft = (this.videoW - this.faceRoiW) / 2
      const initTop = (this.videoH - this.faceRoiW) / 2

      this.faceRoi = new fabric.Rect({
        name: 'faceRoi',
        left: initLeft,
        top: initTop,
        fill: '#F9414433',
        stroke: '#F94144',
        strokeWidth: 2,
        width: this.faceRoiW,
        height: this.faceRoiW,
        hasControls: this.mode === 'edit',
        cornerSize: 10,
        cornerColor: '#F94144',
        transparentCorners: false,
        lockRotation: true, // 禁止旋轉
        lockUniScaling: true, // 等比例縮放
        selectable: this.mode === 'edit' && !this.isEditRoi,
        evented: this.mode === 'edit' && !this.isEditRoi,
      })
      
      const text = new fabric.Text(`${this.$t('ai_face_size_ratio')/*臉部比例*/}：${this.faceRatio}%`, {
        name: 'faceText',
        left: 10,
        top: 3,
        fontSize: 20,
        fill: '#ffffff',
      })

      const rect = new fabric.Rect({
        left: 0,
        top: 0,
        rx: 4,
        ry: 4,
        fill: '#191919',
        stroke: '#ffffff',
        strokeWidth: 1,
        width: text.width + 20,
        height: text.height + 6,
        lockRotation: true, // 禁止旋轉
        lockUniScaling: true, // 等比例縮放
      })

      this.faceRoiText = new fabric.Group([rect, text], {
        name: 'faceRoiText',
        left: initLeft + this.faceRoiW + 10,
        top: initTop,
        selectable: false,
        evented: false,
      })

      this.roi.setControlVisible('mtr', false) // 隱藏旋轉控制點
      this.faceRoi.setControlVisible('mtr', false) // 隱藏旋轉控制點
      this.faceRoiText.setControlVisible('mtr', false) // 隱藏旋轉控制點

      this.canvas.add(this.roi)
      this.canvas.add(this.faceRoi)
      this.canvas.add(this.faceRoiText)
      
      this.faceRoi.on('mouseover',() => {
        this.faceRoiText.visible = true
        this.canvas.renderAll()
      })
      this.faceRoi.on('mouseout',() => {
        this.faceRoiText.visible = false
        this.canvas.renderAll()
      })
      
      this.canvas.on('mouse:down', this.onMouseDown)
      this.canvas.on('object:scaling', this.onObjectScaling)
      this.canvas.on('object:moving', this.onObjectMoving)
      this.canvas.on('object:modified', this.onObjectModified)
    },
    handleLoadedMetadata(event) {
      this.videoRealWidth = event.target.videoWidth // video 實際寬度

      // 臉部比例範圍, 改為 5%-50%, 且要 >= 60 pixel
      this.minFaceRatio = Math.max(5, Math.ceil(60 / this.videoRealWidth * 100))
      this.minFaceRoiW = Math.max(60, Math.ceil(this.videoRealWidth * 0.05))
      this.maxFaceRoiW = Math.ceil(this.videoRealWidth * 0.5)
    },
    handleSelectable() {
      if (!this.roi || !this.faceRoi) return
      this.roi.hasControls = this.canEditRoi
      this.roi.selectable = this.canEditRoi
      this.roi.evented = this.canEditRoi
      this.faceRoi.hasControls = this.canEditTargetRoi
      this.faceRoi.selectable = this.canEditTargetRoi
      this.faceRoi.evented = this.canEditTargetRoi

      if (this.mode === 'view' || this.currDefaultSetting === 1) {
        this.canvas.discardActiveObject()
      } else {
        if (this.isEditRoi)
          this.canvas.setActiveObject(this.roi)
        else
          this.canvas.setActiveObject(this.faceRoi)
      }
      
      this.canvas.renderAll()
    },
    windowResize() {
      if (this.canvas) this.canvas.clear() // remove all object
      this.onInit()
    },
    getVideoWidthHeight() {
      let videoW = this.$refs.video.clientWidth > 0 ? this.$refs.video.clientWidth : this.$refs.noVideo.clientWidth
      let videoH = this.$refs.video.clientHeight > 0 ? this.$refs.video.clientHeight : this.$refs.noVideo.clientHeight
      let realVW = 0
      let realVH = 0
      if (videoW / videoH >= 16 / 9) {
        realVH = videoH
        realVW = Math.round(realVH * 16 / 9)
      } else {
        realVW = videoW
        realVH = Math.round(videoW * 9 / 16)
      }
      return [realVW, realVH]
    },
    playVideo() {
      if (!this.mseUrl) return
      this.player = new mse(this.$refs.video, this.mseUrl)
      this.player.startup()
      this.playPromise = this.$refs.video.play()
    },
    pauseVideo() {
      if (this.player) this.player = null
      if (this.playPromise) {
        this.playPromise.then(() => {
          if (this.$refs.video) this.$refs.video.pause()
        })
        .catch(error => {
          console.log('Pause video error: ', error)
        })
      }
    },
    onMouseDown(event) {
      if (event.target?.name === 'roi') {
        const pointer = this.canvas.getPointer(event.e)
        const x = pointer.x
        const y = pointer.y
        if (x >= this.faceRoi.left && x <= this.faceRoi.left + this.faceRoi.width && y >= this.faceRoi.top && y <= this.faceRoi.top + this.faceRoi.height) {
          this.canvas.setActiveObject(this.faceRoi)
        }
      }
    },
    onObjectMoving(event) {
      if (event.target.name === 'faceRoi') {
        this.updateFaceRoiText()
      }
    },
    onObjectScaling(event) {
      if (event.target.name === 'faceRoi') {
        const rect = event.target
        if (this.videoRealWidth === 0) this.videoRealWidth = this.videoW // 沒有 video 實際寬度時, 以 video 寬度為基準
        const scale = this.videoW / this.videoRealWidth // 縮放比例
        // minFaceRoiW, maxFaceRoiW 以 video 實際寬度為基準, 所以要乘上 scale
        if (rect.width * rect.scaleX < this.minFaceRoiW * scale) {
          rect.set({
            scaleX: this.minFaceRoiW * scale / rect.width,
            scaleY: this.minFaceRoiW * scale / rect.width,
          })
        } else if (rect.width * rect.scaleX > this.maxFaceRoiW * scale) {
          rect.set({
            scaleX: this.maxFaceRoiW * scale / rect.width,
            scaleY: this.maxFaceRoiW * scale / rect.width,
          })
        }
        
        this.handleFaceRatio()
        this.updateFaceRoiText()
      }
    },
    onObjectModified(event) {
      if (event.target.name === 'roi') {
        this.updateRoiData(event)
      }
    },
    updateRoiData(event) {
      let x1 = event.target.aCoords.tl.x / this.videoW
      let y1 = event.target.aCoords.tl.y / this.videoH
      let x2 = event.target.aCoords.br.x / this.videoW
      let y2 = event.target.aCoords.br.y / this.videoH
      
      x1 = x1 < 0 ? 0 : x1
      y1 = y1 < 0 ? 0 : y1
      x2 = x2 > 1 ? 1 : x2
      y2 = y2 > 1 ? 1 : y2 

      this.ROI = { x1, y1, x2, y2 }
    },
    handleFaceRatio() {
      const ratio = Math.ceil(this.faceRoi.width * this.faceRoi.scaleX / this.videoW * 100)
      if (ratio < this.minFaceRatio) this.faceRatio = this.minFaceRatio
      else if (ratio > 50) this.faceRatio = 50
      else this.faceRatio = ratio
    },
    updateFaceRoiText() {
      this.faceRoiText.left = this.faceRoi.left + this.faceRoi.width * this.faceRoi.scaleX + 10
      this.faceRoiText.top = this.faceRoi.top
      
      const obj = this.faceRoiText.getObjects().find(item => item.name === 'faceText')
      if (obj) obj.set({ text: `${this.$t('ai_face_size_ratio')/*臉部比例*/}：${ this.faceRatio }%` })
    },
    updateFaceRoiByRatio() {
      let newRoiW = Math.round(this.videoW * this.faceRatio / 100)
      
      this.faceRoi.set({
        width: newRoiW,
        height: newRoiW,
        scaleX: 1,
        scaleY: 1
      })
      
      this.faceRoiText.left = this.faceRoi.left + newRoiW + 10
      this.faceRoiText.top = this.faceRoi.top
      
      const obj = this.faceRoiText.getObjects().find(item => item.name === 'faceText')
      if (obj) 
        obj.set({ 
          text: `${this.$t('ai_face_size_ratio')/*臉部比例*/}：${ this.faceRatio }%`,
          visible: true,
          selectable: true 
        })
      
      this.canvas.setActiveObject(this.faceRoi)
      this.canvas.bringToFront(this.faceRoiText)
      this.canvas.bringToFront(this.faceRoi)
      this.canvas.renderAll()
    },
  }
}
</script>

<style lang="scss" scoped>
.video-roi {
  width: 100%;
  height: 100%;
  color: #191919;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-stream-info {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #010101;
  color: #666666;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #010101;
  display: block;
  // z-index: -1;
}
</style>